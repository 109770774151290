import { useLayoutEffect, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiGetCategoryMainPageData } from '../../../api/apiCategories';
// import PageLayout from '../layout/PageLayout';
import CarouselBanner from '../banner/CarouselBanner';
import TextHeader from '../../textHeader/TextHeader';
import NewArrivalsGrid from '../../../components/shared/grid/NewArrivalsGrid';
import FeaturedGallery from '../gallery/FeaturedGallery';
import ApplicationsGrid from '../grid/ApplicationsGrid';
import HomewareCategoryGrid from '../grid/HomewareCategoryGrid';
import FilterComponent from '../filter/FilterComponent';
import LoadingScreen from '../loading/LoadingScreen';
import MissingPage from '../errorMessages/MissingPage';
import { captureAnalytics } from '../../../utilities/Analytics';

const CategoryTemplate = () => {
  const { item_group } = useParams();
  const location = useLocation();

  const itemGroupData = {
    category: { item_group: item_group },
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: categoryData,
  } = useQuery<any, Error>(
      [`main-category-${item_group}`],
      () => apiGetCategoryMainPageData(itemGroupData),
      { staleTime: 600000 } // Align with ApplicationsTemplate
  );

  // **1. Save Scroll Position Before Navigation**
  useEffect(() => {
    return () => {
      sessionStorage.setItem(`scroll-position-${location.pathname}`, window.scrollY.toString());
    };
  }, [location.pathname]);

  // **2. Restore Scroll Position Immediately Upon Mount**
  useLayoutEffect(() => {
    const savedPosition = sessionStorage.getItem(`scroll-position-${location.pathname}`);
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10));
      sessionStorage.removeItem(`scroll-position-${location.pathname}`); // Optional: Clean up
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, item_group]);

  // **3. Analytics Capture**
  useEffect(() => {
    if (isSuccess && categoryData) {
      const title = categoryData.page_title;
      captureAnalytics('pageview', location.pathname, title);
    }
  }, [location.pathname, item_group, isSuccess, categoryData]);

  return (
      <>
        {isError && <MissingPage />}
        {isLoading ? (
            <LoadingScreen />
        ) : (
            <>
              {isSuccess && (
                  <>
                    <CarouselBanner
                      heroImages={categoryData?.section_consumer_hero}
                      heroVideo={categoryData?.section_consumer_hero_video ? {
                        video_url: categoryData.section_consumer_hero_video.video_url,
                        thumbnail_url: categoryData.section_consumer_hero_video.thumbnail_url || ''
                      } : undefined}
                      heroLink={categoryData?.section_consumer_hero_link}
                      videoLink={categoryData?.section_consumer_video_link}
                    />
                    <TextHeader
                        heading={categoryData?.page_title}
                        content={categoryData?.description}
                        isCentered={false}
                        width="70%"
                        marginTop={10}
                        marginBottom={5}
                    />
                    {item_group === '000' ? (
                        <HomewareCategoryGrid
                            data={categoryData?.section_homeware_landing_categories}
                            spacingValue={6}
                            marginTop={5}
                            marginBottom={5}
                        />
                    ) : (
                        <ApplicationsGrid
                            data={categoryData?.section_application_cards}
                            hasTitles={false}
                            hasButtons={false}
                            hasBackgroundText={true}
                            spacingValue={4}
                            marginTop={15}
                            marginBottom={15}
                        />
                    )}
                    <FeaturedGallery
                        images={categoryData?.section_featured_collection}
                        hasVideo={false}
                        invert={false}
                        marginTop={5}
                        marginBottom={5}
                        title={categoryData?.featured_collection_title?.collection_name}
                        description={categoryData?.featured_collection_description}
                        itemGroup={categoryData?.featured_collection_title?.item_group_code}
                        application={categoryData?.featured_collection_title?.application_name}
                        collection={categoryData?.featured_collection_title?.collection_name}
                    />
                    <NewArrivalsGrid
                        header="New arrivals"
                        data={categoryData?.section_new_arrivals}
                        hasTitles={true}
                        hasButtons={true}
                        hasBackgroundText={false}
                        spacingValue={4}
                        marginTop={'5rem'}
                        marginBottom={'5rem'}
                    />
                    <FilterComponent
                        itemGroup={item_group}
                        title={categoryData?.page_title}
                        initialApplications={[]}
                    />
                  </>
              )}
            </>
        )}
      </>
  );
};

export default CategoryTemplate;
