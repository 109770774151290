import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';
import Filter from './Filter';

interface FilterCategory {
  title: string;
  available: any[]; // Could be { idx: number, ... }[] or string[]
  filtered: any[];  // Could be { id: number }[] or string[]
  list: string[];
  setList: React.Dispatch<React.SetStateAction<string[]>>;
  keyName: string;
  paramKey: string;
}

const FilterSelection = ({
                           // *** Available options (arrays of objects or strings) ***
                           availableColours,
                           availableDesigns,
                           availableTypes,
                           availableBrands,
                           availableMaterials,
                           availableStandards,
                           availableSizes,
                           // *** Filtered options (arrays of { id: number } OR strings) ***
                           filteredColours,
                           filteredDesigns,
                           filteredTypes,
                           filteredBrands,
                           filteredMaterials,
                           filteredStandards,
                           filteredSizes,
                           // *** Currently-selected values ***
                           colourList,
                           setColourList,
                           designList,
                           setDesignList,
                           typesList,
                           setTypesList,
                           brandList,
                           setBrandList,
                           materialList,
                           setMaterialList,
                           standardsList,
                           setStandardsList,
                           sizeList,
                           setSizeList,
                           // *** Handlers ***
                           handleCheckboxChange,
                           applyFilters,
                           // Used to check if any applications are selected before filtering
                           applicationsList,
                         }: any) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({
    Colour: true,
    Design: true,
    Types: true,
    Brand: true,
    Material: true,
    Standards: true,
    Size: true,
  });

  useEffect(() => {
    // If on mobile, collapse all sections by default
    if (isMobile) {
      setVisibility({
        Colour: false,
        Design: false,
        Types: false,
        Brand: false,
        Material: false,
        Standards: false,
        Size: false,
      });
    }
  }, [isMobile]);

  /**
   * A helper that returns only items that appear in the "filtered"
   * array. If the user hasn't selected an application, we'll just return
   * all available items. For "Size", which is stored as strings, we do
   * a simpler includes() check instead of looking for "id".
   */
  const getFilteredOptions = (available: any[], filtered: any[], category: string) => {
    // If no application selected, return entire array
    if (!applicationsList || applicationsList.length === 0) {
      return available;
    }
    // If category is "Size", do string-based filtering
    if (category === 'Size') {
      // For safety, lower-case everything during comparison
      const lowerFiltered = filtered.map((sz: string) => sz.toLowerCase());
      return available.filter((item) => lowerFiltered.includes(item.toLowerCase()));
    }
    // For everything else, assume we have objects with "idx" and "filtered" has { id }
    return available.filter((item) => {
      const itemId = String(item.idx);
      return filtered.some((f: { id: number }) => String(f.id) === itemId);
    });
  };

  // Define your filter categories
  const filterCategories: FilterCategory[] = [
    {
      title: 'Colour',
      available: availableColours,
      filtered: filteredColours,
      list: colourList,
      setList: setColourList,
      keyName: 'colours',
      paramKey: 'colours',
    },
    {
      title: 'Design',
      available: availableDesigns,
      filtered: filteredDesigns,
      list: designList,
      setList: setDesignList,
      keyName: 'design_style',
      paramKey: 'design_style',
    },
    {
      title: 'Types',
      available: availableTypes,
      filtered: filteredTypes,
      list: typesList,
      setList: setTypesList,
      keyName: 'types',
      paramKey: 'types',
    },
    {
      title: 'Brand',
      available: availableBrands,
      filtered: filteredBrands,
      list: brandList,
      setList: setBrandList,
      keyName: 'brand',
      paramKey: 'brand',
    },
    {
      title: 'Material',
      available: availableMaterials,
      filtered: filteredMaterials,
      list: materialList,
      setList: setMaterialList,
      keyName: 'material',
      paramKey: 'material',
    },
    {
      title: 'Standards',
      available: availableStandards,
      filtered: filteredStandards,
      list: standardsList,
      setList: setStandardsList,
      keyName: 'standards',
      paramKey: 'standards',
    },
    // Notice "Size" is stored as strings instead of { id }
    {
      title: 'Size',
      available: availableSizes,
      filtered: filteredSizes,
      list: sizeList,
      setList: setSizeList,
      keyName: '',
      paramKey: 'sizes',
    },
  ];

  return (
    <>
      {filterCategories.map((category) => {
        const filteredAvailable = getFilteredOptions(
          category.available,
          category.filtered,
          category.title
        );

        // If after filtering we have nothing, skip rendering
        if (!filteredAvailable || filteredAvailable.length === 0) {
          return null;
        }

        return (
          <Box key={category.title}>
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography fontWeight="bold" fontSize="20px" mb={2}>
                {category.title}
              </Typography>
              <Box
                onClick={() =>
                  setVisibility((prev) => ({ ...prev, [category.title]: !prev[category.title] }))
                }
                sx={{ cursor: 'pointer' }}
              >
                {visibility[category.title] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </Box>
            </Box>
            {visibility[category.title] && (
              <Filter
                filterType={filteredAvailable}
                handleCheckboxChange={handleCheckboxChange}
                list={category.list}
                setList={category.setList}
                keyName={category.keyName}
                paramKey={category.paramKey}
              />
            )}
          </Box>
        );
      })}
      <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
      <Button
        variant="contained"
        onClick={applyFilters}
        sx={{ width: { xs: '100%', lg: '50%' } }}
        disableElevation
      >
        Apply
      </Button>
      <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
    </>
  );
};

export default FilterSelection;
